import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1073.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1073.000000) scale(0.100000,-0.100000)" >
					<path d="M3815 7612 c-10 -7 -24 -56 -47 -167 -5 -27 -14 -66 -19 -85 -4 -19
-20 -89 -34 -155 -14 -66 -30 -139 -35 -162 -23 -100 -29 -130 -35 -158 -17
-86 -33 -157 -81 -365 -16 -73 -32 -144 -45 -207 -5 -24 -13 -62 -19 -85 -5
-24 -14 -65 -19 -93 -5 -27 -22 -102 -36 -165 -15 -63 -36 -158 -47 -210 -11
-52 -24 -111 -28 -130 -4 -19 -13 -57 -19 -85 -26 -120 -51 -235 -66 -300 -8
-38 -20 -90 -25 -115 -11 -50 -48 -216 -60 -270 -5 -19 -16 -71 -25 -115 -20
-93 -33 -154 -46 -210 -22 -92 -22 -120 -1 -162 33 -64 72 -104 127 -129 l50
-24 861 0 c473 0 863 3 867 6 3 3 -10 29 -28 58 -36 53 -62 144 -50 174 3 9
15 62 27 117 11 55 24 116 28 135 4 19 20 91 35 160 15 69 31 141 35 160 18
81 38 172 60 275 5 28 14 68 19 90 5 22 23 108 40 190 18 83 36 164 41 181 6
17 10 41 10 53 0 27 17 61 31 61 6 0 45 -22 87 -48 42 -27 125 -79 183 -115
59 -37 111 -67 116 -67 6 0 16 14 23 30 10 25 10 33 -1 47 -15 19 -258 257
-385 378 l-79 75 -573 0 c-488 0 -573 -2 -570 -14 2 -8 37 -50 78 -93 41 -44
95 -101 119 -129 25 -27 74 -80 110 -119 37 -38 107 -115 156 -169 50 -55 98
-108 108 -118 13 -13 32 -18 76 -18 33 0 62 -4 66 -9 5 -9 -8 -74 -35 -181 -5
-19 -16 -71 -25 -115 -46 -225 -55 -266 -65 -307 l-11 -38 -294 0 c-285 0
-294 1 -300 20 -3 11 -2 35 4 52 5 18 22 87 36 153 14 66 30 138 35 160 31
134 44 195 51 235 4 25 17 87 28 139 12 52 21 101 21 110 0 9 -19 36 -42 61
-23 25 -67 72 -97 105 -30 33 -82 89 -115 125 -132 141 -246 268 -246 274 0 3
139 6 309 6 235 0 311 3 318 13 6 6 20 62 33 122 26 127 38 184 52 240 5 22
13 60 17 85 14 78 33 154 43 173 8 16 33 17 303 17 181 0 296 -4 300 -10 3 -5
2 -18 -4 -27 -5 -10 -12 -43 -16 -74 l-7 -56 156 -96 c86 -53 160 -97 165 -97
13 0 31 62 62 210 6 30 15 73 20 95 12 53 28 129 39 185 11 58 41 193 75 345
15 66 30 131 32 144 4 14 0 27 -8 32 -17 10 -1800 12 -1814 1z"/>
<path d="M5780 7581 c0 -11 8 -22 18 -24 12 -3 10 -5 -7 -6 -16 -1 -21 -6 -18
-15 3 -8 1 -16 -4 -18 -11 -4 -30 -101 -31 -165 -1 -28 -6 -44 -17 -48 -13 -5
-14 -7 -1 -15 10 -7 11 -10 3 -10 -8 0 -13 -13 -13 -29 0 -17 -4 -33 -10 -36
-5 -3 -10 -24 -10 -46 0 -21 -4 -39 -9 -39 -19 0 -32 -192 -17 -242 5 -19 5
-28 -3 -28 -6 0 -11 11 -11 24 0 41 -19 39 -25 -1 -3 -21 -10 -46 -16 -55 -5
-10 -9 -30 -9 -45 0 -33 -38 -230 -50 -258 -5 -11 -11 -38 -14 -60 -2 -22 -10
-62 -16 -90 -6 -27 -14 -68 -17 -90 -3 -22 -9 -44 -14 -50 -19 -20 -6 -34 92
-100 56 -38 108 -75 115 -82 7 -7 20 -10 28 -7 11 4 16 0 16 -14 0 -11 7 -23
16 -26 22 -9 24 -8 24 9 0 8 9 15 20 15 11 0 20 -7 20 -15 0 -8 -8 -15 -17
-15 -14 0 -12 -4 7 -25 13 -14 25 -23 28 -21 2 2 40 -24 85 -58 45 -34 102
-73 127 -87 42 -23 54 -31 109 -78 13 -12 30 -21 37 -21 7 0 17 -6 21 -13 11
-17 87 -67 102 -67 7 0 21 -10 31 -23 10 -13 37 -32 59 -42 23 -10 41 -23 41
-30 0 -6 10 -17 23 -24 58 -31 159 -101 190 -133 10 -10 23 -18 29 -18 19 0
37 -33 38 -67 0 -39 12 -41 45 -8 14 13 25 20 25 15 0 -9 25 0 120 42 73 32
234 112 247 123 7 6 19 16 27 23 8 6 22 12 30 12 21 0 96 40 96 51 0 5 9 9 19
9 11 0 21 4 23 9 2 5 20 12 41 15 32 5 37 3 37 -14 0 -13 -9 -22 -25 -26 -28
-7 -29 -8 -15 -33 7 -14 4 -23 -15 -39 -36 -32 -31 -48 13 -43 6 1 12 -5 12
-14 0 -8 -3 -15 -8 -15 -4 0 -12 -10 -17 -22 -6 -12 -19 -23 -30 -24 -11 -2
-21 -8 -23 -14 -6 -17 -44 -50 -59 -50 -7 0 -13 -4 -13 -10 0 -5 -9 -10 -19
-10 -11 0 -26 -9 -34 -20 -8 -11 -29 -25 -48 -31 -19 -7 -48 -22 -66 -35 -17
-13 -36 -24 -41 -24 -11 0 -63 -24 -112 -51 -66 -37 -220 -109 -232 -109 -9 0
-9 -3 1 -9 10 -6 25 -4 42 5 16 8 34 14 40 14 7 0 20 6 28 14 9 7 52 28 96 46
44 18 87 38 95 45 8 7 44 25 79 39 36 14 72 32 80 39 9 7 37 24 64 37 26 13
47 26 47 29 0 6 40 25 78 36 12 3 34 22 48 41 15 19 33 34 40 34 8 0 14 5 14
10 0 20 29 10 36 -12 4 -13 11 -26 17 -29 6 -4 1 -19 -12 -37 -25 -36 -28 -67
-5 -58 9 3 18 6 20 6 2 0 4 6 4 14 0 14 29 30 38 21 3 -3 -1 -13 -10 -22 -8
-10 -20 -37 -27 -60 -15 -53 -36 -58 -28 -7 8 45 -15 42 -24 -4 -7 -35 -46
-74 -121 -123 -27 -17 -48 -35 -48 -39 0 -5 -29 -23 -65 -40 -36 -17 -67 -36
-70 -41 -4 -5 -15 -9 -25 -9 -10 0 -20 -3 -22 -7 -1 -5 -30 -21 -63 -37 -33
-16 -67 -33 -75 -37 -8 -4 -35 -18 -60 -29 -25 -12 -53 -26 -62 -31 -21 -11
-55 -12 -60 -1 -1 4 -30 25 -62 47 -32 22 -65 45 -73 50 -9 7 -16 6 -22 -3
-19 -31 -121 106 -121 163 0 8 -4 15 -10 15 -14 0 -29 -68 -28 -125 2 -101 -5
-114 -44 -79 -16 16 -18 15 -24 0 -6 -15 -28 -17 -200 -15 -175 1 -195 3 -204
19 -5 9 -7 20 -3 23 3 4 10 28 14 54 5 27 19 100 32 163 l23 115 -30 22 c-17
12 -35 26 -41 30 -5 5 -23 15 -39 23 -57 29 -66 35 -66 42 0 5 -13 8 -30 8
-38 0 -40 -20 -2 -21 l27 -1 -25 -7 c-16 -4 -31 -2 -43 9 -17 14 -17 15 7 25
25 10 24 11 -26 40 -28 17 -67 40 -87 52 -20 12 -44 22 -53 23 -10 0 -18 7
-18 15 0 8 -7 15 -15 15 -9 0 -18 7 -21 15 -4 8 -11 15 -16 15 -6 0 -30 14
-55 30 -24 17 -46 30 -48 30 -2 0 -32 18 -67 40 -75 47 -78 45 -81 -73 -1 -45
-3 -70 -5 -54 -2 15 -8 27 -14 27 -7 0 -9 -15 -5 -40 3 -27 1 -43 -9 -51 -8
-7 -13 -28 -12 -52 1 -24 -4 -43 -10 -45 -7 -2 -12 -21 -12 -42 0 -21 -4 -42
-10 -45 -5 -3 -10 -28 -10 -55 0 -27 -4 -52 -10 -55 -5 -3 -11 -45 -12 -93 -1
-73 -2 -80 -8 -42 -5 31 -7 36 -8 17 -1 -16 -7 -37 -12 -47 -6 -10 -14 -45
-19 -77 -5 -32 -16 -92 -25 -133 -9 -41 -21 -100 -27 -130 -6 -30 -15 -63 -20
-72 -5 -10 -9 -41 -9 -68 0 -32 -4 -49 -10 -45 -18 11 -10 -36 13 -78 12 -23
33 -54 46 -69 32 -36 101 -72 114 -59 5 5 19 6 31 3 11 -4 357 -7 769 -7 411
1 747 -3 747 -7 0 -11 50 -9 75 2 17 8 16 9 -9 7 -16 -1 -31 3 -33 9 -2 6 10
11 28 12 40 1 58 17 40 35 -11 10 -12 9 -6 -1 6 -11 3 -11 -14 0 -25 15 -27
28 -6 28 8 0 15 4 15 9 0 11 -25 32 -31 26 -3 -2 -6 8 -7 23 -1 15 2 22 6 15
4 -6 24 -14 45 -18 20 -4 37 -11 37 -16 0 -5 18 -9 40 -9 27 0 40 -4 40 -14 0
-8 16 -16 43 -19 44 -6 141 -49 152 -67 10 -16 65 -12 65 5 0 18 -17 20 -22 3
-3 -8 -13 -5 -33 10 -15 12 -35 22 -44 22 -9 0 -26 11 -38 24 -15 16 -39 26
-73 30 -27 4 -50 12 -50 18 0 6 -8 17 -17 24 -17 13 -17 13 0 14 23 0 22 12
-3 28 -11 7 -20 21 -20 32 0 13 -7 20 -20 20 -11 0 -20 6 -20 13 0 7 9 18 20
25 11 7 20 19 20 27 0 8 -9 23 -20 32 -11 10 -20 23 -20 29 0 17 86 22 107 7
9 -7 24 -13 32 -13 8 0 33 -8 55 -18 81 -37 118 -46 127 -31 9 14 9 14 13 -23
0 -7 10 -13 21 -13 11 0 26 -8 33 -18 13 -18 14 -18 28 0 7 10 16 39 20 66 4
26 11 50 15 53 16 10 10 64 -7 64 -9 0 -14 6 -11 13 2 6 8 12 13 12 25 1 34
20 34 71 0 30 4 53 8 50 5 -3 17 39 27 92 10 53 22 101 27 107 4 5 8 28 8 52
0 24 5 43 10 43 6 0 10 18 10 40 0 37 6 46 56 93 57 53 144 174 144 201 0 8 5
18 11 22 14 8 7 170 -8 185 -7 7 -14 -3 -24 -35 -7 -25 -17 -46 -21 -46 -3 0
-9 -8 -13 -19 -7 -23 -50 -71 -65 -71 -5 0 -10 8 -10 18 0 14 2 15 10 2 7 -11
10 -2 10 32 0 26 5 50 10 53 6 3 10 17 10 30 0 13 4 26 9 29 12 8 22 136 13
169 l-7 28 -13 -34 c-12 -32 -16 -35 -52 -33 -22 0 -38 5 -37 11 6 17 -36 45
-50 33 -7 -5 -17 -7 -24 -3 -8 6 -8 9 1 15 15 9 -44 60 -70 60 -10 0 -21 8
-25 18 -3 9 -41 39 -83 67 -42 27 -81 55 -85 62 -4 7 -14 13 -22 13 -7 0 -15
3 -17 8 -4 9 -112 82 -122 82 -4 0 -18 12 -31 26 -13 14 -41 32 -62 39 -21 8
-37 19 -35 24 1 6 -2 11 -8 11 -5 0 -18 9 -29 21 -10 11 -36 27 -58 34 -21 8
-31 14 -23 15 8 0 3 8 -13 16 -16 8 -36 23 -46 32 -26 25 -138 104 -148 104
-4 1 -14 10 -22 20 -9 13 -21 18 -36 15 -15 -2 -22 0 -19 7 3 7 -25 33 -61 60
-52 38 -64 52 -61 69 2 12 10 56 16 97 7 41 17 85 22 97 6 12 8 28 5 36 -3 9
3 32 14 53 10 21 19 43 19 49 0 10 547 10 596 0 25 -5 26 -24 7 -92 -9 -32
-11 -66 -7 -89 5 -26 4 -35 -5 -30 -8 5 -11 -8 -11 -42 0 -52 13 -69 81 -98
14 -7 47 -28 73 -48 26 -20 51 -36 56 -36 5 0 36 -18 69 -40 33 -22 63 -40 66
-40 4 0 13 -6 20 -12 7 -7 43 -31 80 -53 37 -22 87 -53 111 -68 24 -16 50 -30
58 -31 7 -1 31 -17 52 -36 l39 -34 3 40 c2 21 8 43 13 49 5 5 9 22 9 37 0 16
9 63 21 105 11 43 18 85 16 95 -3 9 1 25 9 35 7 10 14 36 14 58 0 23 4 45 9
50 5 6 11 33 13 60 1 28 7 53 12 56 4 4 11 27 13 53 3 25 10 46 15 46 5 0 8
15 6 33 -1 18 2 39 7 47 5 8 13 35 17 60 5 25 14 68 19 95 6 28 12 71 13 98 0
30 5 47 14 47 12 0 13 8 16 105 2 58 -30 116 -76 137 -15 7 -24 16 -22 20 3 4
-4 8 -15 8 -12 0 -20 6 -19 13 2 9 -213 13 -1044 15 -1030 2 -1048 2 -1048
-17z m1532 -28 c-270 -2 -714 -2 -985 0 -270 1 -49 2 493 2 542 0 763 -1 492
-2z m613 -33 c-3 -5 -1 -19 5 -30 7 -13 7 -20 0 -20 -5 0 -10 7 -10 15 0 8 -9
15 -20 15 -27 0 -25 18 3 23 12 2 23 5 25 6 2 0 0 -3 -3 -9z m-2168 -252 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-4 -38 c-3 -11 -7 -20 -9
-20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7 -9 4 -20z m2174 -122 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-107 -98 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1373 -82 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m1403 -55 c0 -19 -3 -24 -10 -17 -6 6 -8
18 -4 27 9 24 14 21 14 -10z m-2173 -55 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m1580 -174 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-807 -79 c22 -16 28 -25 17 -25 -19 0 -67 45 -67 63 0 9 2 9 8 -1 5 -6
23 -23 42 -37z m827 19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m83 -24 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-940 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m1020 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m-1830 -56 c0 -13 -4 -24 -10 -24 -5 0 -8 13 -7 29 2 35 17 31 17
-5z m970 32 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m41 -53 c13 -15 12 -15 -8 -4 -24 12 -29 21 -14 21 5 0 15 -7 22 -17z
m963 -48 c11 -8 16 -15 10 -15 -5 0 -18 7 -28 15 -11 8 -16 15 -10 15 5 0 18
-7 28 -15z m183 9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-77
-31 c-13 -9 -31 -12 -46 -9 -36 9 -22 21 26 23 40 1 41 0 20 -14z m-2010 -133
c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m-80 -81 c0 -8 5 -7 15 1 13 11 14 10 9 -4 -8 -20 -18 -20 -34 -1 -9 10 -9 15
-1 15 6 0 11 -5 11 -11z m80 -33 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16
-2 16 -4z m1395 -6 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-1352 -45 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z
m1428 -23 c-28 -4 -40 6 -21 18 9 6 20 4 29 -3 12 -10 11 -13 -8 -15z m-4 -49
c-9 -9 -20 -13 -24 -9 -4 4 -1 11 7 16 25 16 35 11 17 -7z m-1242 -43 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1565 -200 c0
-5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-1086
-85 c-4 -8 -13 -15 -21 -15 -13 0 -13 3 -3 15 7 8 16 15 21 15 5 0 6 -7 3 -15z
m238 -207 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m93 -18
c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-1005 -4 c0 -2 -12
-11 -27 -20 l-28 -17 24 20 c23 21 31 25 31 17z m954 -12 c9 -24 2 -26 -12 -4
-7 11 -8 20 -3 20 5 0 12 -7 15 -16z m-1312 -39 c3 -14 1 -25 -3 -25 -5 0 -9
11 -9 25 0 14 2 25 4 25 2 0 6 -11 8 -25z m401 18 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z m1787 -23 c9 -16 8 -20 -5 -20 -8 0 -15 9 -15 20
0 11 2 20 5 20 2 0 9 -9 15 -20z m-770 -72 c0 -19 -2 -20 -10 -8 -13 19 -13
30 0 30 6 0 10 -10 10 -22z m-1450 -18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m610 0 c13 -8 13 -10 -2 -10 -9 0 -20 5 -23
10 -8 13 5 13 25 0z m-641 -273 c-1 -17 -2 -18 -6 -4 -2 9 -9 15 -14 12 -10
-7 -12 8 -3 18 12 11 24 -3 23 -26z m2178 -44 c-3 -10 -5 -4 -5 12 0 17 2 24
5 18 2 -7 2 -21 0 -30z m-2200 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m63 2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m625 -170 c-27 -12 -43 -12 -25 0 8 5 22 9 30 9 10 0 8 -3 -5
-9z m925 -50 c0 -12 -8 -12 -35 0 -18 8 -17 9 8 9 15 1 27 -3 27 -9z m-705
-20 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m768 -7 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m427 -13 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1103
-42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1123 -38 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2233 -62
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2216 -13 c-3 -9 -8 -14
-10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-2078 -235 c13 -6 15 -9 5 -9 -8 0
-22 4 -30 9 -18 12 -2 12 25 0z m65 -61 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3
20 7 21 13 19 13 -6z"/>
<path d="M7468 5325 c-5 -25 -4 -25 10 -11 12 11 13 19 5 27 -7 7 -12 3 -15
-16z"/>
<path d="M6665 4979 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M6900 4221 c0 -16 28 -20 34 -5 4 9 -1 14 -14 14 -11 0 -20 -4 -20
-9z"/>
<path d="M3099 3866 c-74 -26 -124 -81 -145 -159 -35 -129 53 -241 177 -224
110 15 169 80 169 188 l0 59 -82 -6 c-111 -8 -110 -7 -106 -51 3 -33 7 -38 31
-41 34 -4 35 -18 1 -26 -63 -16 -98 58 -54 114 17 22 26 25 78 23 l60 -1 27
49 26 49 -43 20 c-50 23 -88 24 -139 6z"/>
<path d="M4752 3873 c-38 -5 -39 -8 -82 -153 -45 -154 -52 -186 -48 -211 3
-21 9 -24 51 -27 43 -3 48 -1 57 22 8 23 15 26 55 26 31 0 47 -5 51 -15 7 -17
59 -20 99 -5 21 8 25 15 20 33 -15 53 -57 184 -65 202 -5 11 -14 38 -21 60
-14 45 -43 76 -68 73 -9 0 -31 -3 -49 -5z m40 -235 c-18 -18 -33 1 -21 24 11
20 12 20 21 4 7 -12 7 -21 0 -28z"/>
<path d="M3672 3857 c-30 -8 -44 -17 -48 -31 -2 -12 -3 -93 -2 -181 l3 -160
55 0 55 0 3 73 c2 39 5 72 8 72 10 0 29 -24 72 -87 35 -52 48 -63 70 -63 16 0
37 6 48 14 18 13 19 28 19 186 0 192 -1 195 -70 185 -21 -3 -41 -8 -44 -11 -3
-3 -5 -30 -4 -60 2 -69 -8 -67 -65 14 -24 34 -47 61 -50 61 -4 -1 -26 -7 -50
-12z"/>
<path d="M4130 3857 c-47 -4 -98 -11 -115 -15 l-30 -8 0 -179 0 -180 104 4
c150 5 156 8 156 62 l0 44 -69 0 c-93 0 -99 20 -9 31 l68 9 0 45 0 45 -65 0
c-50 1 -65 4 -65 15 0 11 18 18 60 24 86 12 85 11 85 55 0 53 -15 59 -120 48z"/>
<path d="M5013 3858 c-23 -11 -23 -14 -23 -189 0 -98 3 -180 8 -183 4 -2 61 1
127 8 l120 11 3 54 3 54 -64 -5 c-36 -3 -70 -3 -76 1 -6 4 -11 56 -13 131 l-3
125 -30 2 c-16 1 -40 -3 -52 -9z"/>
<path d="M3420 3853 c-35 -2 -70 -9 -77 -15 -15 -12 -19 -353 -4 -362 9 -6
240 10 247 17 2 2 5 23 6 48 l3 44 -70 0 c-108 0 -93 30 17 34 31 1 39 5 44
25 3 13 4 35 2 47 -3 22 -8 24 -58 26 -30 1 -63 2 -73 2 -10 1 -17 7 -15 14 3
6 35 16 74 22 l69 10 3 48 3 47 -53 -2 c-29 -1 -82 -3 -118 -5z"/>
<path d="M4345 3851 l-60 -6 -6 -45 c-4 -25 -6 -85 -4 -135 1 -49 3 -114 4
-142 1 -52 2 -53 30 -53 51 0 79 16 83 47 5 43 31 39 69 -11 19 -25 40 -46 46
-46 7 0 27 13 45 29 l31 28 -21 31 c-27 37 -27 43 -2 92 34 67 23 148 -26 191
-23 20 -106 28 -189 20z m123 -136 c3 -40 -9 -55 -44 -58 -26 -2 -29 1 -32 31
-5 52 3 64 40 60 29 -3 33 -7 36 -33z"/>
<path d="M6341 3745 c3 -89 1 -114 -8 -110 -7 2 -15 -6 -19 -20 -7 -27 -19
-33 -27 -12 -4 9 -8 9 -15 -2 -9 -12 -12 -12 -24 0 -13 12 -27 49 -34 84 -1 6
-2 45 -3 89 -1 65 -3 77 -15 67 -7 -6 -21 -9 -30 -5 -10 4 -18 0 -23 -12 -4
-10 -2 -19 5 -22 7 -2 12 -11 11 -20 0 -11 -2 -12 -6 -4 -12 31 -23 6 -23 -54
0 -47 3 -63 12 -58 8 5 10 -6 5 -42 -3 -27 -2 -40 2 -30 6 17 8 17 14 4 3 -9
3 -26 0 -37 -4 -16 -2 -20 10 -15 21 8 44 -5 28 -16 -7 -4 -2 -11 14 -18 21
-10 25 -9 25 3 0 8 5 15 11 15 6 0 9 -7 5 -15 -3 -8 -2 -15 2 -15 18 1 82 27
77 32 -3 3 6 8 20 12 14 4 25 12 25 18 0 6 6 19 14 27 8 9 16 36 18 61 2 25 7
51 11 57 5 7 7 44 6 83 l-2 70 -45 0 -44 0 3 -115z m-151 59 c0 -8 -4 -12 -10
-9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m210 -89 c0 -14 -4 -25
-10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z m-216 -10 c3 -8 1 -15 -4 -15
-6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m208 -60 c0 -5 -5 -11 -11
-13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-192 -10 c0 -8 -4
-15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m76 -82 c-10 -10 -19 5 -10
18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m41 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M6530 3845 c-7 -8 -18 -15 -26 -15 -10 0 -13 -34 -13 -166 l0 -167
62 7 c116 13 144 23 184 62 41 42 42 64 0 101 -29 26 -39 63 -17 63 5 0 14 9
20 20 14 26 -6 77 -32 86 -10 3 -18 10 -18 15 0 16 -146 11 -160 -6z m100 -25
c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m60
-20 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-45 -23 c15 -15 10 -87 -6 -87 -5 0 -6 6 -3 14 4 11 -1 13 -30 6 -40 -9 -46
-6 -46 27 0 14 -4 21 -10 18 -18 -11 -11 17 8 31 21 15 67 11 87 -9z m44 -114
c0 -25 -1 -26 -9 -8 -12 27 -12 35 0 35 6 0 10 -12 9 -27z m-30 -19 c13 -30 4
-59 -22 -73 -21 -10 -20 -10 7 -8 16 1 31 -3 33 -9 3 -8 -12 -11 -46 -9 -59 3
-90 31 -91 79 0 16 3 26 6 23 4 -3 15 0 25 7 30 20 76 15 88 -10z m-139 -19
c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m213
-27 c-13 -12 -25 -19 -28 -16 -8 7 23 38 38 38 6 0 2 -10 -10 -22z m-209 -53
c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z"/>
<path d="M5862 3818 c-32 -20 -47 -59 -43 -113 2 -38 37 -67 74 -62 15 2 27 0
27 -4 0 -4 14 -9 31 -11 18 -2 37 -11 43 -21 16 -26 -16 -47 -70 -47 -24 0
-46 -5 -49 -10 -4 -7 -14 -3 -24 9 -19 22 -38 17 -20 -6 9 -10 8 -13 -2 -13
-8 -1 -3 -7 11 -16 23 -14 23 -14 3 -9 -26 7 -32 -10 -7 -19 9 -3 47 -6 86 -6
63 0 73 3 94 25 13 14 28 25 34 25 16 0 12 17 -6 24 -8 3 -12 10 -9 16 4 6 10
8 15 5 16 -10 21 34 8 62 -16 34 -85 70 -108 55 -10 -7 -24 -5 -42 4 -31 16
-33 22 -14 47 10 13 28 17 80 17 62 0 88 13 49 23 -14 4 -13 5 3 6 22 1 40 26
26 35 -5 3 -42 4 -84 4 -57 0 -83 -5 -106 -20z m13 -48 c-3 -5 -12 -10 -18
-10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-5 -38 c0 -4 -8 -15 -19 -24 -18 -15
-19 -14 -17 8 1 16 7 24 19 24 9 0 17 -3 17 -8z m5 -42 c3 -6 -1 -7 -9 -4 -18
7 -21 14 -7 14 6 0 13 -4 16 -10z m141 -135 c-1 -18 -6 -25 -21 -24 -11 0 -14
3 -7 6 6 2 12 13 12 24 0 10 4 19 9 19 5 0 8 -11 7 -25z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
